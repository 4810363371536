import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"

function FourOhFour() {
  // const [validated, setValidated] = useState(false)


  return (
    <Layout>
      <p className="text-2xl mb-5">Yep, you're lost.</p>
      <Link to="/" className="btn">Take me Home.</Link>
    </Layout>
  )
}

export default FourOhFour
